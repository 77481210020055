.burger-menu {
  position: fixed;
  z-index: 999;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 25px;
  cursor: pointer;
  justify-content: space-around;
}

.line {
  width: 100%;
  height: 4px;
  background-color: #fff;
  transition: all 0.3s ease;
}

.line-1.active {
  transform: translateY(8px) rotate(45deg);
}

.line-2.active {
  opacity: 0;
}

.line-3.active {
  transform: translateY(-8px) rotate(-45deg);
}

.nav {
  position: fixed;
  z-index: 998;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
}

.nav.open {
  opacity: 0.95;
  visibility: visible;
}

.nav > ul {
  list-style: none;
  text-align: center;
}

.nav > ul > li {
  margin-bottom: 2rem;
}

.nav > ul > li > a {
  text-decoration: none;
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  transition: all 0.3s ease;
}

.nav > ul > li > a:hover {
  color: #ff6600;
}
