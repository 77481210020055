@media (min-width: 767px) {
  .burger-menu {
    display: none;
  }
  .regular-menu {
    display: inline-block;
  }
}

@media (max-width: 768px) {
  .regular-menu {
    display: none;
  }
  .burger-menu {
    display: flex;
  }
}
